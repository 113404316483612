/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import * as React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import 'emoji-mart/css/emoji-mart.css';
import { debounce } from 'lodash';
import { Fragment, useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import TextareaAutosize from 'react-textarea-autosize';
import { ADD_MESSAGE, READ_MESSAGES } from '../../../actions/Messages';
import { ReactComponent as EmojiIcon } from '../../../assets/icons/emoji.svg';
import { ReactComponent as SendMessageIcon } from '../../../assets/icons/send-message.svg';
import { ConfigContext } from '../../../context/ConfigContext';
import AttachmentUpload from '../../AttachmentUpload';
import EmojiPicker from '../EmojiPicker';

const FooterUI = styled.div`
  background-color: white;
  padding: 1.25em 1em 1em 1rem;
  height: max-content;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
`;

const TextArea = styled(TextareaAutosize)`
  width: 100%;
  border: 0;
  outline: none;
  resize: none;
  transition: height 0.3s linear;
`;

const ChatOptions = styled.div`
  position: relative;
  padding-left: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  flex-direction: ${isMobile ? 'column' : 'row'};
`;

const onKeyPress = callOnEnter => e => {
  if ((e.key === 'Enter' || (e.charCode || e.keyCode) === 13) && e.shiftKey === false) {
    e.preventDefault();
    callOnEnter();
  }
};

const onEnter = (mutation, config, message, setMessage, setLastEnter) => {
  if (!message) return;
  mutation({
    variables: {
      chatUserId: config.user.id,
      channelId: config.channel.id,
      message: message,
    },
  });
  setMessage('');
  setLastEnter(new Date());
};

const throttledEnter = (mutation, config, message, setMessage, lastEnter, setLastEnter) => {
  // lodash throttle didnt work..
  if (Math.abs(lastEnter - new Date()) < 500) return;
  onEnter(mutation, config, message, setMessage, setLastEnter);
};

const Footer = () => {
  const [message, setMessage] = useState('');
  const [lastEnter, setLastEnter] = useState(new Date());
  const [pickerVisible, setPicker] = useState(false);
  const config = useContext(ConfigContext);

  const onFocus = readMessagesMutation => () => {
    localStorage.setItem('window_focus', 'true');
    window.parent.postMessage(JSON.stringify({ action: 'read_messages' }), '*');
    const channelId = config.channel.id;
    const chatUserId = config.user.id;
    readMessagesMutation({ variables: { channelId, chatUserId } });
  };
  return (
    <Fragment>
      <EmojiPicker
        style={{
          maxHeight: pickerVisible ? '35%' : '0%',
        }}
        config={config}
        setPicker={setPicker}
        message={message}
        setMessage={setMessage}
      />

      <FooterUI>
        <Mutation mutation={READ_MESSAGES} variables={{ channelId: config.channel.id, chatUserId: config.user.id }}>
          {readMessagesMutation => (
            <Mutation
              mutation={ADD_MESSAGE}
              variables={{
                chatUserId: config.user.id,
                channelId: config.channel.id,
                message: message,
                skillIds: config.skills,
                contextMessage: config.contextMessage,
                sessionId: config.sessionId
              }}
            >
              {(mutation) => (
                <>
                  <TextArea
                    onFocus={onFocus(readMessagesMutation)}
                    maxLength={500}
                    autoFocus={true}
                    value={message}
                    onChange={({ target: { value } }) => setMessage(value)}
                    placeholder={
                      config.translations.placeholder[config.translations.locale] ||
                      config.translations.placeholder.DEFAULT
                    }
                    maxRows={6}
                    onKeyPress={onKeyPress(() =>
                      throttledEnter(mutation, config, message, setMessage, lastEnter, setLastEnter)
                    )}
                  />
                  <ChatOptions>
                    <SendMessageIcon
                      style={{ color: 'rgb(92, 45, 145)', width: '28px' }}
                      onClick={() => throttledEnter(mutation, config, message, setMessage, lastEnter, setLastEnter)}
                    />
                    <AttachmentUpload config={config} />
                    {!isMobile && (
                      <EmojiIcon
                        css={{
                          marginLeft: '5px',
                          color: '#6a6a6a',
                          opacity: '0.5',
                          ':hover': { opacity: 1 },
                          transition: 'opacity 0.15s linear',
                        }}
                        onClick={debounce(() => setPicker(!pickerVisible), 50)}
                      />
                    )}
                  </ChatOptions>
                </>
              )}
            </Mutation>
          )}
        </Mutation>
      </FooterUI>
    </Fragment>
  );
};

export default Footer;
